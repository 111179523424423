import {Item, ItemPackaging, ItemPrice, OrderSchedule, RateDetail} from "../../../../corelogic/models/item";
import {
    EnumData,
    ItemBrand,
    ItemFamily,
    ItemGeographicalOrigin,
    SuperItemFamily,
    Unit,
    VatType
} from "../../../../corelogic/models/parameter";
import {Image} from "../../../../corelogic/models/user";
import {getDateFromMilliseconds} from "./utils";

export interface ItemApiWithCountResponse {
    count: number
    items: ItemApiResponse[]
}

export interface ItemApiResponse {
    id: number
    code: string
    commercialDesignation?: string
    label?: string
    brand?: ItemBrand
    superFamily?: SuperItemFamily
    family?: ItemFamily
    geographicalOrigin?: ItemGeographicalOrigin
    packagings: ItemPackaging[]
    itemPrice?: ItemPrice
    degreeOfAlcohol?: number
    density?: number
    barcodeType?: EnumData
    barcode?: string
    width?: number
    height?: number
    depth?: number
    dimensionUnit?: EnumData
    byHowMuch?: number
    packagePerLayer?: number
    layerPerPallet?: number
    netWeight?: number
    grossWeight?: number
    weightUnit?: EnumData
    orderUnit?: Unit
    vatType?: VatType
    picture?: Image | File
    coef?: number
    bio?: boolean
    priceExTax?: number
    stock?: number
    favorite?: boolean
    authorizationToSellByConsumerUnit?: boolean
    authorizationToSellByPackage?: boolean
    referenced?: boolean
    ingredients?: string
    nutritionalValues?: string
    presentation?: string
    organic?: boolean
    manufactured?: boolean
    sold?: boolean
    managedInStock?: false
    purchased?: true
    blocked?: false
    targetStock?: number
    minimumStock?: number
    safetyStock?: number
    minimumReplenishmentQuantity?: number
    multipleReplenishment?: number
    supplyLeadTime?: number
    publishedInTheCatalog?: boolean
    ecommerceExport?: boolean
    physicalStock?: number
    purchasesOutstanding?: number
    salesOutstanding?: number
    lastImportDatetime?: number
    orderSchedule?: OrderScheduleResponse
    rateDetails?: RateDetailApiResponse[]
    promotionalRateDetails?: RateDetailApiResponse[]
}

export interface OrderScheduleResponse {
    lastOrderDate: number
    lastOrderQuantity: number
    lastOrderGrossPrice: number
    lastOrderDiscount1?: number
    lastOrderDiscount2?: number
    lastOrderNetPrice: number
    lastOrderItemLabel: string
}

export interface RateDetailApiResponse {
    id: number
    price: number
    discount: number
    minimumQuantity: number
    startDate?: number
    endDate?: number
}

export const mapItemApiResponseToItem = (itemResponse: ItemApiResponse): Item => {
    return {
        ...itemResponse,
        lastImportDatetime: getDateFromMilliseconds(itemResponse.lastImportDatetime),
        orderSchedule: itemResponse.orderSchedule && mapOrderScheduleApiResponseToOrderSchedule(itemResponse.orderSchedule),
        rateDetails: (itemResponse.rateDetails || []).map(x => mapRateDetailApiResponseToRateDetail(x)),
        promotionalRateDetails: (itemResponse.promotionalRateDetails || []).map(x => mapRateDetailApiResponseToRateDetail(x)),
    }
}


export const mapOrderScheduleApiResponseToOrderSchedule = (orderScheduleResponse: OrderScheduleResponse): OrderSchedule => {
    return {
        ...orderScheduleResponse,
        lastOrderDate: getDateFromMilliseconds(orderScheduleResponse.lastOrderDate),
    }
}


export const mapRateDetailApiResponseToRateDetail = (rateDetailApiResponse: RateDetailApiResponse): RateDetail => {
    return {
        ...rateDetailApiResponse,
        startDate: getDateFromMilliseconds(rateDetailApiResponse.startDate),
        endDate: getDateFromMilliseconds(rateDetailApiResponse.endDate),
    }
}