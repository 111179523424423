import {
    Alert,
    AppBar,
    BadgeProps,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    TextField,
    Theme,
    Toolbar,
    Tooltip,
    useMediaQuery
} from "@mui/material";
import {generatePath, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {SearchBar} from "../../../Utils/SearchBar";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {FormattedMessage, useIntl} from "react-intl";
import messages, {getMessageDescriptor} from "../../../../../../i18n/messages";
import {UserRoleCode} from "../../../../../../corelogic/models/user";
import CloseIcon from '@mui/icons-material/Close';
import {
    retrieveAllCustomersFiltered,
    retrieveCustomerAddresses,
    retrieveCustomerById
} from "../../../../../../corelogic/usecase/customer/customersActions";
import {APP_ACTIONS_TYPES} from "../../../../../../store/AppActionsTypes";
import {
    getCustomerSelectedDeliveryAddresses,
    getCustomerSelectedSelector,
    getCustomersSelector
} from "../../../../../../store/selectors/customersSelector";
import StoreIcon from '@mui/icons-material/Store';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import {Address, Customer, CustomerFilter} from "../../../../../../corelogic/models/customer";
import {FixedSizeList, ListChildComponentProps} from 'react-window';
import AutoSizer, {Size} from 'react-virtualized-auto-sizer';
import ReturnButton from "../../../Utils/ReturnButton";
import {DividingHR} from "../../../Utils/DividingHR";
import {TextValueField} from "../../../Utils/TextValueField";
import {getUserLoggedSelector} from "../../../../../../store/selectors/sessionSelector";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import {styled} from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import {useDebounce} from "@uidotdev/usehooks";
import * as yup from "yup";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers";
import moment from "moment/moment";
import MenuItem from "@mui/material/MenuItem";
import {AddressDisplayBlock} from "../../../Utils/Address";
import {OrderInitialize} from "../../../../../../corelogic/models/order";
import {initNewOrder} from "../../../../../../corelogic/usecase/orders/ordersActions";

const StyledBadge = styled(Badge)<BadgeProps>(({theme}) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))

interface OrderListPageHeaderProps {
    ordersCount?: number
    onChange?: (input: string) => void
    getToggleFiltersState?: (state: boolean) => void
    filterCount: number
}

enum WorkFlowStep {
    CLOSE,
    CUSTOMER_LIST,
    ALERT_AMOUNT_DUE,
    INIT_ORDER
}

export const OrderListPageHeader = (props: OrderListPageHeaderProps) => {
    const {ordersCount, onChange, getToggleFiltersState, filterCount} = props
    const dispatch = useDispatch()
    const {userLogged} = useSelector(getUserLoggedSelector)
    const {customerSelected} = useSelector(getCustomerSelectedSelector)
    const {customerSelectedAddresses, fetchingAddressesSelected} = useSelector(getCustomerSelectedDeliveryAddresses)
    const {data: customerList, fetching: customerFetching} = useSelector(getCustomersSelector)
    const navigation = useNavigate()
    const intl = useIntl()
    const [workflowStep, setWorkflowStep] = useState(WorkFlowStep.CLOSE)
    const [openCustomerListModal, setOpenCustomerListModal] = useState(false)
    const [openCustomerAlertAmountDueDialog, setOpenCustomerAlertAmountDueDialog] = useState(false)
    const [openCustomerInitOrder, setOpenCustomerInitOrder] = useState(false)
    const [toggleFilters, setToggleFilters] = useState<boolean>(true)
    const [searchBarValue, setSearchBarValue] = useState("")
    const isLargeScreenUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"))
    const delaySearchValue = useDebounce(searchBarValue, 500)

    const clearSelectedUser = useCallback(() => {
        if (userLogged?.role?.code !== UserRoleCode.CUSTOMER)
            dispatch<any>({type: APP_ACTIONS_TYPES.customers.CUSTOMER_RESET_SELECTION})
        dispatch<any>({type: APP_ACTIONS_TYPES.customers.CUSTOMER_RESET_DATA})
    }, [dispatch, userLogged?.role?.code])

    useEffect(() => {
        if (userLogged?.role?.code === UserRoleCode.CUSTOMER && userLogged.linkedCustomer) {
            dispatch(retrieveCustomerById(userLogged.linkedCustomer?.id))
        }
    }, [dispatch, userLogged?.linkedCustomer, userLogged?.role?.code])

    useEffect(() => {
        if (customerSelected?.id)
            dispatch(retrieveCustomerAddresses(customerSelected.id))
    }, [customerSelected?.id, dispatch])

    useEffect(() => {
        if (onChange !== undefined) {
            onChange(delaySearchValue)
        }
    }, [onChange, delaySearchValue])

    useEffect(() => {
        setOpenCustomerListModal(workflowStep === WorkFlowStep.CUSTOMER_LIST)
        setOpenCustomerAlertAmountDueDialog(workflowStep === WorkFlowStep.ALERT_AMOUNT_DUE)
        setOpenCustomerInitOrder(workflowStep === WorkFlowStep.INIT_ORDER)
        if ([WorkFlowStep.CLOSE, WorkFlowStep.CUSTOMER_LIST].includes(workflowStep))
            clearSelectedUser()
    }, [clearSelectedUser, workflowStep])

    const [customerFilter, setCustomerFilter] = useState<CustomerFilter>({
        search: "",
        block: false,
        ca: [],
        sf: [],
        fa: [],
        iv: undefined,
        zc: undefined,
        rp: undefined,
        va: [],
        re: [],
        ra: undefined
    })

    useEffect(() => {
        if (openCustomerListModal) {
            dispatch<any>(retrieveAllCustomersFiltered(customerFilter))
        }
    }, [dispatch, customerFilter, openCustomerListModal])

    const handleChangeCustomerSearchInput = useCallback((e?: string) => {
        setCustomerFilter(prev => ({...prev, search: e}))
    }, [])

    const handleCreateNewOrder = () => {
        if (userLogged?.role?.code === UserRoleCode.CUSTOMER) {
            if (userLogged?.linkedCustomer) {
                if (customerSelected?.amountDue && customerSelected.amountDue > 0)
                    setWorkflowStep(WorkFlowStep.ALERT_AMOUNT_DUE)
                else
                    setWorkflowStep(WorkFlowStep.INIT_ORDER)
            }
        } else {
            setWorkflowStep(WorkFlowStep.CUSTOMER_LIST)
        }
    }

    const handleChangeSearchInput = (e: string) => {
        setSearchBarValue(e)
    }

    const handleResetSearchBar = () => {
        setSearchBarValue("")
    }

    const handleValidateCustomerListModal = (customer: Customer) => {
        dispatch(retrieveCustomerById(customer.id))
        if (customer?.amountDue && customer.amountDue > 0) {
            setWorkflowStep(WorkFlowStep.ALERT_AMOUNT_DUE)
        } else {
            setWorkflowStep(WorkFlowStep.INIT_ORDER)
        }

    }

    const handleCloseCustomerAlertAmountDueModal = () => {
        if (userLogged?.role?.code === UserRoleCode.CUSTOMER)
            setWorkflowStep(WorkFlowStep.CLOSE)
        else
            setWorkflowStep(WorkFlowStep.CUSTOMER_LIST)
    }

    const handleValidateCustomerOrderInitModal = (orderInitialize: OrderInitialize) => {
        if(customerSelected?.id) {
            dispatch(initNewOrder(customerSelected?.id, orderInitialize))
            navigation(generatePath("/orders/creation"))
        }else{
            setWorkflowStep(WorkFlowStep.CLOSE)
        }
    }

    const handleCloseCustomerListModal = () => {
        setWorkflowStep(WorkFlowStep.CLOSE)
    }

    const handleValidateCustomerAlertAmountDueModal = () => {
        setWorkflowStep(WorkFlowStep.INIT_ORDER)
    }

    const handleCloseCustomerOrderInitModal = () => {
        if (userLogged?.role?.code === UserRoleCode.CUSTOMER)
            setWorkflowStep(WorkFlowStep.CLOSE)
        else
            setWorkflowStep(WorkFlowStep.CUSTOMER_LIST)
    }

    const handleOpenFilters = () => {
        setToggleFilters(prevState => !prevState)
        if (getToggleFiltersState) {
            getToggleFiltersState(toggleFilters)
        }
    }

    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center" p={1}>
                <Grid item>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <ReturnButton/>
                        </Grid>
                        <Grid item mx={2}>
                            <DividingHR
                                subContent
                                style={{height: "30px", width: 1, margin: 0}}/>
                        </Grid>
                        <Grid item mr={1}>
                            <StyledBadge badgeContent={filterCount} color="error">
                                {toggleFilters ?
                                    <Tooltip
                                        title={intl.formatMessage(getMessageDescriptor("itemListFilterOpenTitle"))}>
                                        <Button variant="contained" onClick={handleOpenFilters}>
                                            <MenuIcon sx={{marginRight: "5px", marginBottom: "1px"}}/>
                                        </Button>
                                    </Tooltip>
                                    :
                                    <Tooltip
                                        title={intl.formatMessage(getMessageDescriptor("itemListFilterCloseTitle"))}>
                                        <Button variant="contained" onClick={handleOpenFilters}>
                                            <MenuOpenIcon sx={{marginRight: "5px", marginBottom: "1px"}}/>
                                        </Button>
                                    </Tooltip>
                                }
                            </StyledBadge>
                        </Grid>
                        <Grid item mx={2}>
                            <DividingHR
                                subContent
                                style={{height: "30px", width: 1, margin: 0}}/>
                        </Grid>
                        <Grid item>
                            <TextValueField
                                style={{
                                    fontWeight: "bolder"
                                }}
                                text={intl.formatMessage(getMessageDescriptor("orderListPageHeaderTitle"))}
                                value={ordersCount}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Tooltip title={intl.formatMessage(getMessageDescriptor("orderListPageHeaderButton"))}>
                                <Button variant="contained" onClick={handleCreateNewOrder}>
                                    <NoteAddIcon sx={{marginRight: "5px", marginBottom: "1px"}}/>
                                    {isLargeScreenUp &&
                                        <FormattedMessage {...getMessageDescriptor("orderListPageHeaderButton")}/>}
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <SearchBar label={intl.formatMessage(getMessageDescriptor("searchBarOrder"))}
                                       onChange={handleChangeSearchInput} onResetClick={handleResetSearchBar}
                                       value={searchBarValue}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {openCustomerListModal && <DialogCustomerSelection onCloseClick={handleCloseCustomerListModal}
                                                               onSelectCustomer={handleValidateCustomerListModal}
                                                               customers={customerList}
                                                               customerFetching={customerFetching}
                                                               onCustomerSearchInputChange={handleChangeCustomerSearchInput}
                                                               defaultSearch={customerFilter.search}/>}
            {(customerSelected && openCustomerAlertAmountDueDialog) &&
                <DialogCustomerAlertAmountDue onCloseClick={handleCloseCustomerAlertAmountDueModal}
                                              onValidateClick={handleValidateCustomerAlertAmountDueModal}
                                              customer={customerSelected}
                                              isCustomer={userLogged?.role?.code === UserRoleCode.CUSTOMER}/>}
            {(customerSelected && openCustomerInitOrder) &&
                <DialogCustomerOrderInit onCloseClick={handleCloseCustomerOrderInitModal}
                                         customerSelected={customerSelected}
                                         customerSelectedAddresses={customerSelectedAddresses}
                                         fetchingCustomerSelectedAddresses={fetchingAddressesSelected}
                                         onValidateClick={handleValidateCustomerOrderInitModal}/>}
        </>
    )
}

interface DialogCustomerSelectionProps {
    onCloseClick?: () => void
    onSelectCustomer?: (customer: Customer) => void
    onCustomerSearchInputChange: (searchInput?: string) => void
    customers: Customer[]
    customerFetching?: boolean
    defaultSearch?: string
}

export const DialogCustomerSelection = (props: DialogCustomerSelectionProps) => {
    const {
        onCloseClick,
        onSelectCustomer,
        customerFetching,
        customers,
        onCustomerSearchInputChange,
        defaultSearch = ""
    } = props
    const intl = useIntl()
    const [searchBarInput, setSearchBarInput] = useState<string>(defaultSearch)
    const delaySearchValue = useDebounce(searchBarInput, 500)

    useEffect(() => {
        if (onCustomerSearchInputChange)
            onCustomerSearchInputChange(delaySearchValue)
    }, [delaySearchValue, onCustomerSearchInputChange])

    const handleCloseModal = () => {
        if (onCloseClick) {
            onCloseClick()
        }
    }

    const handleListItemClick = (customerSelected: Customer) => {
        if (onSelectCustomer) {
            onSelectCustomer(customerSelected)
        }
    }

    const handleSearchBarChange = (value: string) => {
        setSearchBarInput(value)
    }

    const handleResetSearchBar = () => {
        setSearchBarInput("")
    }

    const handleResetFilter = () => {
        setSearchBarInput("")
    }


    function renderRow(props: ListChildComponentProps) {
        const {index, style} = props
        const dataCustomer = customers[index]

        return (
            <ListItem style={style} key={index} component="div"
                      onClick={() => handleListItemClick(dataCustomer)}>
                <ListItemButton>
                    <ListItemIcon>
                        <StoreIcon/>
                    </ListItemIcon>
                    <ListItemText>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Grid container>
                                    <Grid item>
                                        <Typography color="text.primary" fontWeight="bolder">
                                            <FormattedMessage {...getMessageDescriptor("customerPageFormFieldBusinessName")}/>
                                            :
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography color="secondary.main" fontWeight="bolder"
                                                    marginLeft="5px">{dataCustomer.businessName}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container>
                                    <Grid item>
                                        <Typography color="text.primary" fontWeight="bolder">
                                            <FormattedMessage {...getMessageDescriptor("customerPageFormFieldCode")}/>
                                            :
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography color="secondary.main" fontWeight="bolder"
                                                    marginLeft="5px">{dataCustomer.code}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Typography color="text.primary" fontWeight="bolder" fontSize="small">
                                <FormattedMessage {...getMessageDescriptor("customerPageFormFieldAddress")}/>
                                :
                            </Typography>
                            <Typography color="secondary.main" fontSize="small"
                                        marginLeft="5px">
                                {`${dataCustomer.address1 ? dataCustomer.address1 : ""}${dataCustomer.zipCode1 ? ", " + dataCustomer.zipCode1 : ""}${dataCustomer.city ? ", " + dataCustomer.city : ""}`}
                            </Typography>
                        </Grid>
                    </ListItemText>
                </ListItemButton>
            </ListItem>
        )
    }

    return <Dialog
        fullWidth
        maxWidth={"md"}
        open={true}
        onClose={handleCloseModal}
        sx={{overflowY: "hidden"}}
    >
        <AppBar sx={{
            position: 'relative', backgroundColor: "background.main"
        }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleCloseModal}
                    aria-label="close"
                >
                    <CloseIcon sx={{fill: "rgb(31,141,229)"}}/>
                </IconButton>
                <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div" color="text.primary">
                    <FormattedMessage {...getMessageDescriptor("orderCreationPageHeaderDialogSelectCustomer")}/>
                </Typography>
                <SearchBar onChange={handleSearchBarChange} onResetClick={handleResetSearchBar}
                           value={searchBarInput}/>
            </Toolbar>
        </AppBar>
        <Paper sx={{height: "75vh", overflowY: "hidden"}}>
            {customerFetching ?
                <Grid container height="100%" justifyContent="center" alignItems="center">
                    <CircularProgress size={60}/>
                </Grid>
                : (customers.length > 0 ?
                        <AutoSizer>
                            {({height, width}: Size) => (
                                <FixedSizeList
                                    height={height}
                                    width={width}
                                    itemSize={80}
                                    itemCount={customers.length}
                                    overscanCount={5}
                                >
                                    {renderRow}
                                </FixedSizeList>
                            )}
                        </AutoSizer>
                        :
                        <Grid container justifyContent="center" p={1}>
                            <Alert severity="info" sx={{alignItems: "center"}}>
                                {intl.formatMessage(getMessageDescriptor("orderCreationPageHeaderDialogSelectCustomerNotFound"))}
                                <Button variant="outlined" onClick={handleResetFilter} sx={{ml: 1}}>
                                    {intl.formatMessage(getMessageDescriptor("genericRetry"))}
                                </Button>
                            </Alert>
                        </Grid>
                )}
        </Paper>
    </Dialog>
}


interface DialogCustomerAlertAmountDueProps {
    onCloseClick?: () => void
    onValidateClick?: () => void
    customer: Customer
    isCustomer?: boolean
}

export const DialogCustomerAlertAmountDue = (props: DialogCustomerAlertAmountDueProps) => {
    const {onCloseClick, onValidateClick, customer, isCustomer} = props
    const intl = useIntl()

    const handleCloseAlertDialog = () => {
        if (onCloseClick)
            onCloseClick()
    }
    const handleValidateAlertDialog = () => {
        if (onValidateClick)
            onValidateClick()
    }

    return <Dialog
        open={true}
        onClose={handleCloseAlertDialog}
    >
        <DialogTitle id="alert-dialog-title">
            <FormattedMessage id={messages.orderCreationCustomerAmountDueControlTitle.id}/>
        </DialogTitle>
        <DialogContent>
            <DialogContentText m={1}>
                <Alert
                    severity="warning">
                    {intl.formatMessage(getMessageDescriptor(isCustomer
                        ? "orderCreationCustomerAmountDueControlCustomerView"
                        : "orderCreationCustomerAmountDueControl"
                    ), {amountDue: new Intl.NumberFormat().format(customer?.amountDue || 0)})
                    }
                </Alert>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button variant="outlined" color="error" onClick={handleCloseAlertDialog}><FormattedMessage
                id={messages.genericCancel.id}/></Button>
            <Button variant="outlined" onClick={handleValidateAlertDialog}><FormattedMessage
                id={messages.genericConfirm.id}/></Button>
        </DialogActions>
    </Dialog>
}

type InputsFormOrderInit = {
    reference?: string
    delayRequested?: Date
    address?: string
}

interface DialogCustomerInitOrderProps {
    onCloseClick?: () => void,
    onValidateClick?: (inputs: OrderInitialize) => void,
    customerSelected: Customer,
    customerSelectedAddresses?: Address[],
    fetchingCustomerSelectedAddresses?: boolean
}

export const DialogCustomerOrderInit = (props: DialogCustomerInitOrderProps) => {
    const {
        onCloseClick,
        onValidateClick,
        customerSelected,
        customerSelectedAddresses = []
    } = props
    const intl = useIntl()
    const minDate = moment().add(1, "day").toDate()

    const schemaValidationGeneralInformation = yup.object({
        reference: yup.string().notRequired(),
        delayRequested: yup.date().min(minDate, `Date minimum ${intl.formatDate(minDate)}`).notRequired()
            .typeError('Format de la date invalide'),
        address: yup.string().notRequired()
    })

    const {
        handleSubmit,
        formState: {errors},
        control,
        setValue,
        reset
    } = useForm<InputsFormOrderInit>(
        {
            mode: 'onChange',
            resolver: yupResolver(schemaValidationGeneralInformation)
        }
    )

    useEffect(() => {
        setValue('delayRequested', moment().add(customerSelected?.transitTime || 1, "day").toDate())
    }, [customerSelected, setValue]);

    useEffect(() => {
        const defaultAddress = customerSelectedAddresses && customerSelectedAddresses.length > 0 ? customerSelectedAddresses.find(x => x.title === 'Principale') : null
        setValue('address', defaultAddress ? JSON.stringify(defaultAddress) : "")
    }, [customerSelectedAddresses, setValue])

    const handleCloseOrderInitDialog = () => {
        reset()
        if (onCloseClick)
            onCloseClick()
    }
    const handleValidateOrderInitDialog: SubmitHandler<InputsFormOrderInit> = (data) => {
        const orderInit: OrderInitialize = {...data, address: JSON.parse(data.address || "")}
        /*if (customerSelected) {
            dispatch(initNewOrder(customerSelected.id, orderInit))
            navigation(generatePath("/orders/creation"))
        }*/
        if (onValidateClick)
            onValidateClick(orderInit)
    }

    return <Dialog
        open={true}
        onClose={handleCloseOrderInitDialog}
        component="form"
        onSubmit={handleSubmit(handleValidateOrderInitDialog)}
    >
        <DialogTitle id="alert-dialog-title">
            Alimentation des zone de commande par défaut
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={4} p={2}>
                <Grid item xs={6}>
                    <Controller
                        name="reference"
                        control={control}
                        render={({field}) => <TextField
                            {...field}
                            autoFocus
                            fullWidth
                            variant="standard"
                            label={intl.formatMessage((getMessageDescriptor("orderReference")))}
                            placeholder={intl.formatMessage((getMessageDescriptor("orderReference")))}
                            type="text"
                            error={!!errors.reference}
                            helperText={errors.reference?.message}/>
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="delayRequested"
                        control={control}
                        render={({field}) => <LocalizationProvider
                            dateAdapter={AdapterMoment}>
                            <DatePicker
                                label={intl.formatMessage(getMessageDescriptor("orderCreationPageHeaderDeliveryDelay"))}
                                inputFormat="DD/MM/YYYY"
                                minDate={minDate}
                                inputRef={field.ref}
                                onChange={field.onChange}
                                value={field.value}
                                renderInput={(params) => <TextField fullWidth {...params}
                                                                    variant="standard"
                                                                    helperText={errors.delayRequested?.message}
                                                                    error={!!errors.delayRequested}
                                />}
                            />
                        </LocalizationProvider>
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="address"
                        control={control}
                        render={({field}) => <TextField
                            {...field}
                            fullWidth
                            id="delay-selector"
                            select
                            label={intl.formatMessage(getMessageDescriptor("orderDeliveryAddress"))}
                        >
                            {(customerSelectedAddresses || []).map(x =>
                                <MenuItem
                                    key={x.id}
                                    value={JSON.stringify(x)}>
                                    <Grid container>
                                        <Paper sx={{width: "100%", padding: "10px"}}>
                                            <AddressDisplayBlock
                                                title={x.title}
                                                businessName={x.businessName}
                                                address1={x.address1}
                                                address2={x.address2}
                                                address3={x.address3}
                                                zipCode1={x.zipCode1}
                                                zipCode2={x.zipCode2}
                                                city={x.city}
                                                country={x.country?.label}/>
                                        </Paper>
                                    </Grid>
                                </MenuItem>)}
                        </TextField>
                        }
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button variant="outlined" color="error" onClick={handleCloseOrderInitDialog}><FormattedMessage
                id={messages.genericCancel.id}/></Button>
            <Button variant="outlined" type="submit"><FormattedMessage
                id={messages.genericConfirm.id}/></Button>
        </DialogActions>
    </Dialog>
}